<template>
  <div>

    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-card :title="$t('groups.view')">
          <actions
            slot="actions"
            crud-links="groups"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          />
          <text-list :title="$t('tables.headings.name')">
            {{ group.name }}
          </text-list>
          <text-list :title="$t('tables.headings.super_admin')">
            {{ $t(group.super ? 'layout.yes' : 'layout.no') }}
          </text-list>
        </va-card>
      </div>
      <div
        class="flex xs12"
        v-if="permissions.length > 0"
      >
        <va-card :title="$t('groups.permissions')">
          <div class="row">
            <div
              class="flex xs12 sm6 md4"
              v-for="permission in permissions"
              :key="permission.label"
            >
              <permission-view
                :label="permission.label"
                :category="permission.acos"
              ></permission-view>
            </div>
          </div>
        </va-card>
      </div>
    </div>

  </div>
</template>

<script>
const PermissionView = () => import(/* webpackPrefetch: true */ './TreeView')
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'groups-view',
  components: {
    Actions,
    TextList,
    PermissionView,
  },
  data () {
    return {
      error: false,
      group: {},
      permissions: [],
      loading: false,
      actions: ['index', 'new', 'edit', 'delete'],
    }
  },
  created () {
    this.updateData()
  },
  methods: {
    routeBuilder (id) {
      return `groups/${id}`
    },
    updatePermissions (acos) {
      this.permissions = []

      Object.keys(acos).forEach(k => {
        const permission = {
          label: k,
          acos: acos[k],
        }

        this.permissions.push(permission)
      })
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const groupId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(groupId))
      } catch (err) {
        // console.log('Error fetching group data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.group = u.data.data
      if (this.group.acos) {
        this.updatePermissions(this.group.acos)
      }
    },
  },
}
</script>
